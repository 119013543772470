// import * as DELETED_JSON from '../../deleted.json';

import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import CardGrid from '../../components/CardGrid';
import ClientCard from './ClientCard';
import ContextAttribute from '../../components/ContextAttribute';
import DebugOnly from '../../components/DebugOnly';
import DynamicIcon from '../../components/DynamicIcon';
import { EMPTY_ARRAY } from '../../shared/helpers';
import ErrorBoundary from '../../components/ErrorBoundary';
import Paginator from '../../components/Paginator';
import TextureButton from '../../components/TextureButton';
import TitleBar from '../../components/MainContent/TitleBar';
import { addExportTypeToAllExports } from '../../admin-functions/addExportTypeToAllExports';
import { exportSurveysDeviceType } from '../../admin-functions/exportLastUpdatedWebAssets';
import fixSurveyMetaStatuses from '../../admin-functions/fixSurveyMetaStatuses';
import { makeStyles } from '@mui/styles';
import { removeDataArrayFromTaxonomies } from '../../admin-functions/removeDataArrayFromTaxonomies';
import { resaveAllAssetsForClient } from '../../admin-functions/resaveAllAssetsForSurvey';
import { updateUserRoleForClient } from '../../admin-functions/updateUserRoleForClient';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '16px',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  welcome: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 24,
  },
}));

const asseticomButtons = [
  {
    icon: 'rosette',
    colour: 'green',
    text: 'ADD NEW',
    textBold: 'CLIENT PORTAL',
    linkTo: '/clients/add',
  },
  {
    icon: 'users',
    colour: 'blue',
    text: 'ADD NEW',
    textBold: 'ASSETICOM USER',
    linkTo: '/users/add',
  },
];

function Bomb() {
  throw new Error('💥 CABOOM 💥');
}

const Dashboard = () => {
  const classes = useStyles();

  const [clientsPerPage, setClientsPerPage] = useState(30);
  const [cursor, setCursor] = useState(0);

  const clients = useSelector(
    (state) => state.firestore.ordered.clients || EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'clients',
      storeAs: 'clients',
      limit: clientsPerPage,
      startAt: cursor,
      orderBy: ['created', 'desc'],
      where: ['deleted', '==', 0],
    },
  ]);

  const stats = useSelector(
    (state) => state.firestore.data.stats || EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'globals',
      doc: 'stats',
      storeAs: 'stats',
    },
  ]);
  const firestore = useFirestore();

  const removeSpaces = (s) => s.replace(/\s/g, '');

  const fixAssetsWithCodeSpaces = async () => {
    const clientId = 'cbre-experian';
    const surveyId = 'T6VvCQuEWooU6DPGL520';
    const allAssets = await firestore
      .collectionGroup('assets')
      .where('surveyId', '==', surveyId)
      .where('deleted', '==', 0)
      .get();

    allAssets.docs.forEach((assetDoc) => {
      const assetData = assetDoc.data();
      const updatedAttributes = {
        ...assetData.attributes,
        code0: removeSpaces(assetData.attributes.code0),
        code1: removeSpaces(assetData.attributes.code1),
        code2: removeSpaces(assetData.attributes.code2),
        code3: removeSpaces(assetData.attributes.code3),
      };
      const update = {
        ...assetData,
        attributes: updatedAttributes,
        code: updatedAttributes.code3,
      };

      // assetDoc.ref.update(update);
    });

    console.log({ allAssets: allAssets.size });
  };

  const findAssetsWithMissingAttributes = async () => {
    // const clientId = 'cbre-experian';
    const surveyId = 'w2kr6IYchd30IVvMNZnI';

    const allAssets = await firestore
      .collectionGroup('assets')
      .where('surveyId', '==', surveyId)
      .where('deleted', '!=', 0)
      .get();

    const missingAttr = [];
    const missingCode = [];

    allAssets.docs.forEach((assetDoc) => {
      const assetData = assetDoc.data();
      const attributes = assetData.attributes;
      let hasMissingAttribute = false;
      Object.values(attributes).forEach((value) => {
        if (value === null || value === '') {
          hasMissingAttribute = true;
        }
      });
      if (hasMissingAttribute) {
        missingAttr.push(assetData);
      }
      if (assetData.code === '' || assetData.code === null) {
        missingCode.push(assetData);
      }
      console.log('code3', assetData.code, attributes.code3);
    });

    console.log({ missingAttr });
    console.log({ missingCode });
  };

  const findAssetsWithMissingValuesOrAttributes = async () => {
    const clientId = 'cbre-experian';
    const surveyId = 'JbXuAGKnWl3Ps3Hr3ABB';

    console.log('searching survey', surveyId);

    const batch = new BigBatch({ firestore });

    const survey = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys')
      .doc(surveyId)
      .get();

    console.log({ survey });

    const { assetListId } = survey.data();

    console.log('assetListId', assetListId);

    const assetListDoc = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('assetLists')
      .doc(assetListId)
      .get();

    const assetList = assetListDoc.data();

    console.log({ assetList });

    if (!assetList || !assetListId) {
      return console.log('nope');
    }

    const missing = [];

    const allAssets = await firestore
      .collectionGroup('assets')
      .where('surveyId', '==', surveyId)
      // .where('deleted', '==', 0)
      .get();

    allAssets.docs.forEach((assetDoc) => {
      const assetData = assetDoc.data();
      const attributes = assetData.attributes;
      const values = assetData.values;
      let hasMissingAttributes = typeof assetData.attributes.cost !== undefined;
      // let hasMissingValues = Object.values(values).length === 0;
      if (hasMissingAttributes) {
        missing.push(assetData);
        if (assetData.code) {
          const assetType = assetList.data.find(
            (i) => i.code3 === assetData.code,
          );
          if (!assetType) {
            console.log('no asset type for assetData', assetData);
          } else {
            const updatedAttrs = {
              code0: assetType.code0,
              code1: assetType.code1,
              code2: assetType.code2,
              code3: assetType.code3,
              label0: assetType.label0,
              label1: assetType.label1,
              label2: assetType.label2,
              label3: assetType.label3,
              uom: assetType.uom,
              costPerUnit: assetType.costPerUnit,
              expectedLife: assetType.expectedLife,
              uplift: assetType.uplift,
            };
            batch.update(assetDoc.ref, {
              attributes: updatedAttrs,
            });
            console.log({ updatedAttrs });
          }
        } else {
          console.log('no code', assetData);
        }
      }
    });

    batch.commit();

    console.log({ missing });
  };

  const removeDuplicateRooms = async () => {
    const clientId = 'cbre-experian';
    const surveyId = 'JbXuAGKnWl3Ps3Hr3ABB';
    const surveyDoc = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys')
      .doc(surveyId)
      .get();
    const surveyData = surveyDoc.data();

    const { roomSchedule } = surveyData;

    const lengthBeforeRemove = roomSchedule.data.length;

    console.log({ lengthBeforeRemove });

    const withoutDuplicateRooms = roomSchedule.data.filter((r) => {
      if (r.name.indexOf('VARIOUS AREAS - VIRTUAL ASSETS') === 0) {
        return true;
      } else if (r.name.indexOf('PLANT ROOM - LIFT MOTOR ROOM') === 0) {
        return true;
      }
      return false;
    });

    console.log({ withoutDuplicateRooms });
  };

  const surveys = [
    'Pxpw3CGNqGHsJevzECII',
    'w2kr6IYchd30IVvMNZnI',
    'AuXspF867SEguG0V3byS',
    'KOqsvfceylWjxXJrHi7K',
  ];

  return (
    <Grid container spacing={2} pt={2}>
      <DebugOnly>
        <Button
          onClick={() => {
            console.log('add an admin function bozo');
          }}
        >
          Run Admin Function
        </Button>
      </DebugOnly>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        {asseticomButtons.map((config) => {
          const { colour, icon, text, textBold, linkTo } = config;
          return (
            <Grid key={linkTo} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextureButton colour={colour} linkTo={linkTo}>
                <DynamicIcon name={icon} height="25" colour={colour} inCircle />
                <Box
                  marginTop={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography className={classes.text} align="center">
                    {text}
                  </Typography>
                  <Typography
                    className={`${classes.text} ${classes.textBold}`}
                    align="center"
                  >
                    {textBold}
                  </Typography>
                </Box>
              </TextureButton>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.clientsNumCreated || 0}
                type="Active Portals"
              />
              <ContextAttribute
                value={stats.clientsNumDeleted || 0}
                type="Inactive Portals"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.usersNumCreated || 0}
                type="Users Created"
              />
              <ContextAttribute
                value={stats.usersNumDeleted || 0}
                type="Users Deleted"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.assetsNumCreated || 0}
                type="Assets Collected"
              />
              <ContextAttribute
                value={stats.assetsNumDeleted || 0}
                type="Assets Deleted"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.partialsNumCreated || 0}
                type="Partials Collected"
              />

              <ContextAttribute
                value={stats.partialsNumDeleted || 0}
                type="Partials Deleted"
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Paper>
            {/* <Box display="flex" alignItems="center" p={2}>
              <Box mr={2}>
                <TextField
                  type="search"
                  variant="outlined"
                  label="Search Client Portals"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DynamicIcon
                          name="search"
                          colour="secondary"
                          width="20px"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button variant="contained" color="secondary">
                Search
              </Button>
            </Box> */}
            {clients.length ? (
              <Box p={2}>
                <ErrorBoundary>
                  <CardGrid
                    dataArray={clients}
                    render={(client) => <ClientCard client={client} />}
                  />
                  <Stack direction="row" justifyContent="flex-end">
                    <Paginator
                      label="Clients per page:"
                      total={stats.clientsNumCreated}
                      perPage={clientsPerPage}
                      perPageOptions={[6, 12, 18, 24, 30]}
                      onChangePage={setCursor}
                      onChangePerPage={setClientsPerPage}
                      dataArray={clients}
                      dataProp="created"
                    />
                  </Stack>
                </ErrorBoundary>
              </Box>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;

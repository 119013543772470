import { Box, CardActionArea, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardAttribute from './CardAttribute';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import { sitePropTypes } from '../../../shared/propTypes';

const SiteCard = ({ site }) => {
  const createdDateString = new Date(site.created);
  const location = useLocation();

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActionArea
        component={Link}
        to={`${location.pathname}/sites/${site.id}`}
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box display="flex" alignItems="center">
              <Typography color="secondary" fontWeight={200} fontSize={22}>
                {site.name}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <CardAttribute
              type="Buildings"
              value={site.buildingsNumCreated - site.buildingsNumDeleted}
            />
            <CardAttribute
              type="Surveys"
              value={site.surveysNumCreated - site.surveysNumDeleted}
            />
            <CardAttribute
              type="Created"
              value={createdDateString.toLocaleDateString()}
              align="left"
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

SiteCard.propTypes = {
  site: PropTypes.shape(sitePropTypes).isRequired,
};

export default SiteCard;

import 'leaflet/dist/leaflet.css';

import {
  Box,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';

import { ALL_ROLES } from '../../../config/roles';
import BuildingCard from './BuildingCard';
import CardGrid from '../../../components/CardGrid';
import ComponentGuard from '../../../components/ComponentGuard';
import ContextAttribute from '../../../components/ContextAttribute';
import DeleteClientButton from '../../../components/DeleteClientButton';
import DynamicIcon from '../../../components/DynamicIcon';
import IconShadow from '../../../assets/marker-shadow.png';
import L from 'leaflet';
import MarkerComplete from '../../../assets/marker-icon-2x.png';
import { NAV_ROUTES_MAP } from '../../../config/routes';
import SiteCard from './SiteCard';
import SurveyCard from './SurveyCard';
import TabPanel from '../../../components/TabPanel';
import TextureButton from '../../../components/TextureButton';
import UserCard from './UserCard';
import { makeStyles } from '@mui/styles';
import { setSnackMessage } from '../../../state/ui';
import { useAppDispatch } from '../../../config/store';
import { useSelector } from 'react-redux';

const DefaultIcon = L.icon({
  iconUrl: MarkerComplete,
  shadowUrl: IconShadow,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '16px',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  tabs: { backgroundColor: theme.palette.background.paper },
}));

const MAX_CARDS_TO_SHOW = 8;

const portalButtons = [
  {
    icon: 'building',
    colour: 'secondary',
    text: 'ADD NEW',
    textBold: 'SITE & BUILDINGS',
    linkTo: NAV_ROUTES_MAP.SITES_ADD.path,
    auth: ALL_ROLES.LEAD_SURVEYOR,
  },
  {
    icon: 'edit',
    colour: 'secondary',
    text: 'ADD NEW',
    textBold: 'SURVEY',
    linkTo: NAV_ROUTES_MAP.SURVEYS_ADD.path,
    auth: ALL_ROLES.LEAD_SURVEYOR,
  },
  {
    icon: 'survey',
    colour: 'secondary',
    text: 'VIEW',
    textBold: 'SURVEYS',
    linkTo: NAV_ROUTES_MAP.SURVEYS.path,
    auth: ALL_ROLES.SURVEYOR,
  },
  {
    icon: 'document',
    colour: 'secondary',
    text: 'CREATE',
    textBold: 'ASSET REPORTS',
    linkTo: NAV_ROUTES_MAP.REPORTING_CREATE.path,
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const client = useSelector((state) => state.firestore.data.client);

  const firestore = useFirestore();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const mapRef = useRef();

  const sites = useSelector((state) => state.firestore.ordered.sites);

  const sitesParsed = useMemo(() => {
    if (sites?.length) {
      return sites.filter((site) => {
        const { lat, lng } = site;
        return lat && lng;
      });
    }
    return [];
  }, [sites]);

  const bounds = useMemo(() => {
    if (!sitesParsed.length) {
      return null;
    }
    const bounds = new L.LatLngBounds();
    sitesParsed.forEach((site) => {
      const { lat, lng } = site;
      bounds.extend([lat, lng]);
    });
    return bounds;
  }, [sitesParsed]);

  if (!client) {
    return null;
  }

  function onClientDelete() {
    firestore
      .collection('clients')
      .doc(client.id)
      .update({ deleted: Date.now() })
      .then(() => {
        history.push('/');
      });
    dispatch(
      setSnackMessage({
        message: `${client.name} portal deleted`,
        type: 'error',
      }),
    );
  }

  return (
    <Grid container spacing={2} id="main-content">
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        {portalButtons.map((config) => {
          const { colour, icon, text, textBold, linkTo, auth } = config;
          const fullLink = `${client.clientId}${linkTo}`;

          return (
            <ComponentGuard auth={auth} key={fullLink} label={fullLink}>
              <Grid item xs={12} sm={6} md={3} xl={2}>
                <TextureButton colour={colour} linkTo={fullLink}>
                  <DynamicIcon name={icon} size="25" colour={colour} inCircle />
                  <Box
                    marginTop={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography color={colour} align="center" fontSize={14}>
                      {text}
                    </Typography>
                    <Typography
                      align="center"
                      color={colour}
                      fontSize={16}
                      fontWeight={500}
                    >
                      {textBold}
                    </Typography>
                  </Box>
                </TextureButton>
              </Grid>
            </ComponentGuard>
          );
        })}
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        <Grid item xs={12} sm={4}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={client.sitesNumCreated - client.sitesNumDeleted}
                type="Number of Sites"
              />
              <ContextAttribute
                value={client.buildingsNumCreated - client.buildingsNumDeleted}
                type="Number of Buildings"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={client.surveysNumCreated - client.surveysNumDeleted}
                type="Number of Surveys"
              />
              <ContextAttribute
                value={client.usersNumCreated - client.usersNumDeleted}
                type="Number of Users"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={client.assetsNumCreated - client.assetsNumDeleted}
                type="Assets Collected"
              />
              <ContextAttribute
                value={client.partialsNumCreated - client.partialsNumDeleted}
                type="Partials Collected"
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Typography variant="subtitle2" px={2} pt={1}>
              All Sites
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.light,

                width: '100%',
                height: '500px',
                p: 2,
                pt: 1,
              }}
            >
              {bounds ? (
                <MapContainer
                  whenCreated={(m) => {
                    mapRef.current = m;
                  }}
                  zoomSnap={0.5}
                  bounds={bounds}
                  boundsOptions={{ padding: [50, 50] }}
                  scrollWheelZoom={true}
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=e90d6bdb4bf6435db613f186dbac1af8"
                  />

                  {sitesParsed?.map((site) => (
                    <Marker
                      key={site.id}
                      position={[site.lat, site.lng]}
                      icon={DefaultIcon}
                      eventHandlers={{
                        click: (e) => {
                          history.push(
                            `${client.id}/surveys?filters=siteName*${site.name}`,
                          );
                        },
                      }}
                    >
                      <Tooltip>{site.name}</Tooltip>
                    </Marker>
                  ))}
                </MapContainer>
              ) : null}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <ComponentGuard auth={ALL_ROLES.ASSETICOM_ADMIN}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ flex: 1, my: 2 }}
        >
          <DeleteClientButton onDelete={onClientDelete} />
        </Stack>
      </ComponentGuard>
    </Grid>
  );
};

export default Dashboard;

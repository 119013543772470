import { Box, CardActionArea, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardAttribute from './CardAttribute';
import CardContent from '@mui/material/CardContent';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import React from 'react';
import { buildingPropTypes } from '../../../shared/propTypes';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  type: theme.typography.attributeType,
}));

const BuildingCard = ({ building }) => {
  const classes = useStyles();
  const createdDateString = new Date(building.created);
  const location = useLocation();

  return (
    <Card>
      <CardActionArea
        component={HashLink}
        smooth
        to={`${location.pathname}/sites/${building.siteId}#buildings`}
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box display="flex" alignItems="center">
              <Typography color="secondary" fontWeight={200} fontSize={22}>
                {building.name}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <CardAttribute type="Site" value={building.siteName} align="left" />

            <CardAttribute
              type="Created"
              value={createdDateString.toLocaleDateString()}
              align="left"
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

BuildingCard.propTypes = {
  building: PropTypes.shape(buildingPropTypes).isRequired,
};

export default BuildingCard;

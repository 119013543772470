import { AppState, useAppDispatch } from '../config/store';
import { FormOptions, SurveyProps } from '../types/survey';
import {
  autoSizeWorksheetWidth,
  getSafeExcelSheetName,
  getStyledExportHeaders,
  getStyledRows,
} from '../shared/utilities';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';

import { DEFAULT_VALUES_FORM_OPTIONS } from '../routes/client-portal/surveys/steps/Fields/constants';
import { FormField } from '../types/form';
import { UrlParams } from '../types/urlParams';
import XLSX from 'xlsx-js-style';
import { applyFormFieldsDefaults } from '../routes/client-portal/surveys/steps/Fields/utils';
import { getAssetListById } from '../hooks/useAssetList';
import { useCallback } from 'react';
import useImportSchema from '../routes/client-portal/surveys/steps/Import/hooks/useImportSchema';
import { useParams } from 'react-router-dom';

async function wait() {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), 2000);
  });
}

type State = SurveyProps & { importData: any };

const surveyFormSlice = createSlice({
  name: 'surveyForm',
  initialState: {} as State,
  reducers: {
    setFormData(state, action) {
      console.log('setting form data', action.payload);
      return {
        ...state,
        ...action.payload,
      };
    },
    resetFormData(state, action) {
      console.log('resetting form data', action.payload);
      return action.payload;
    },
  },
});

export default surveyFormSlice.reducer;

export const { setFormData, resetFormData } = surveyFormSlice.actions;

const allState = (state: AppState) => state;

const surveyFormState = (state: AppState) => state.surveyForm;

export const overviewSelector = createSelector([surveyFormState], (state) => {
  const {
    name,
    buildingId,
    buildingName,
    siteId,
    siteName,
    gifa,
    external,
    surveyorId,
    surveyType,
    surveyorName,
    surveyorEmail,
    estimatedStartDate,
    estimatedDuration,
    postCode,
    region,
  } = state;
  return {
    name,
    buildingId,
    buildingName,
    siteId,
    siteName,
    gifa,
    external,
    surveyorId,
    surveyType,
    surveyorName,
    surveyorEmail,
    estimatedStartDate,
    estimatedDuration,
    postCode,
    region,
  };
});

export const useOverviewStepData = () =>
  useSelector(overviewSelector, shallowEqual);

export const assetListIdSelector = createSelector(
  [surveyFormState],
  (state) => {
    return state.assetListId;
  },
);

export const fieldsSelector = createSelector([allState], (state: AppState) => {
  const formFields = state.surveyForm.formFields;
  const formOptions = state.surveyForm.formOptions;
  const assetListId = state.surveyForm.assetListId;

  const withDefaultsIfNeeded = applyFormFieldsDefaults({
    formFields: formFields ?? [],
  });

  return {
    assetListId,
    formOptions: formOptions || DEFAULT_VALUES_FORM_OPTIONS,
    formFields: withDefaultsIfNeeded,
  };
});

export const useFieldStepData = () => useSelector(fieldsSelector, shallowEqual);

export const importSelector = createSelector([surveyFormState], (state) => {
  const {
    formFields,
    formOptions,
    importData,
    roomSchedule,
    assetsNumImported,
    name,
  } = state;
  return {
    formFields,
    formOptions: formOptions || DEFAULT_VALUES_FORM_OPTIONS,
    importData,
    roomSchedule,
    assetsNumImported,
    name,
  };
});

export const useImportStepData = () => {
  const {
    formFields,
    formOptions,
    importData,
    roomSchedule,
    assetsNumImported,
    name,
  } = useSelector(importSelector, shallowEqual);

  const { schema, columns } = useImportSchema({ formFields, formOptions });

  return {
    formFields,
    formOptions,
    importData,
    roomSchedule,
    assetsNumImported,
    name,
    schema,
    columns,
  };
};

export const roomScheduleSelector = createSelector(
  [surveyFormState],
  (state) => {
    const { roomSchedule, importData, name, floorPlans } = state;
    return {
      roomSchedule,
      importData,
      floorPlans,
      surveyName: name,
    };
  },
);

export const useRoomScheduleStepData = () =>
  useSelector(roomScheduleSelector, shallowEqual);

export const floorPlansSelector = createSelector([surveyFormState], (state) => {
  const { roomSchedule, floorPlans } = state;
  return {
    roomSchedule,
    floorPlans,
  };
});

export const useFloorPlansStepData = () =>
  useSelector(floorPlansSelector, shallowEqual);

export const onChangedAssetListId =
  ({ assetListId }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const surveyFormState = getState().surveyForm;
    const hasAddedFields = surveyFormState.formFields?.filter(
      (f: FormField) => !f.base,
    ).length;

    return !!(
      hasAddedFields &&
      surveyFormState.assetListId &&
      assetListId !== surveyFormState.assetListId
    );
  };

function ensureQuantityFieldIsDecimal(formFields: FormField[]) {
  return formFields.map((field) => {
    if (field.id === 'Quantity' || field.label === 'Quantity') {
      return {
        ...field,
        type: 'Decimal',
      };
    }
    return field;
  });
}

export const useFormFieldMerger = () => {
  const dispatch = useAppDispatch();
  const clientId = useParams<UrlParams>().clientId;

  const formFields = useSelector(
    (state: AppState) => state.surveyForm.formFields,
  );

  const mergeFormFields = useCallback(
    async (assetListId: string) => {
      const assetList = await getAssetListById(clientId, assetListId);
      const chosenListCode3List = assetList.map((i) => i.code3);

      const formFieldsWithUpdatedNicheFields = formFields?.map((field) => {
        if (field.base) {
          return {
            ...field,
            nicheFields: [],
          };
        }
        const keptFields = field.nicheFields?.filter((code3) =>
          chosenListCode3List.includes(code3),
        );
        return {
          ...field,
          nicheFields: keptFields,
        };
      });

      dispatch(
        setFormData({
          formFields: ensureQuantityFieldIsDecimal(
            formFieldsWithUpdatedNicheFields,
          ),
          assetListId,
        }),
      );
    },
    [formFields, dispatch],
  );
  return { mergeFormFields };
};

export const exportRoomSchedule =
  () =>
  async (dispatch, getState: () => AppState, { getFirebase, getFirestore }) => {
    const {
      name: surveyName,
      siteName,
      buildingName,
      roomSchedule,
    } = getState().surveyForm;
    const parsedList = roomSchedule?.data.map((roomData) => {
      return {
        floor: roomData.floor,
        name: roomData.name,
        areaFloor: roomData.areaFloor,
        areaWall: roomData.areaWall,
      };
    });

    const headers = getStyledExportHeaders([
      'Site Name',
      'Building Name',
      'Floor Name',
      'Room Name',
      'Area Floor',
      'Area Wall',
    ]);

    const workbook = XLSX.utils.book_new();
    const roomRows = parsedList?.map((room) => {
      return [
        {
          v: siteName,
          t: 's',
        },
        {
          v: buildingName,
          t: 's',
        },
        {
          v: room.floor,
          t: 's',
        },
        {
          v: room.name,
          t: 's',
        },
        {
          v: (room.areaFloor || 0).toFixed(2),
          t: 'n',
        },
        {
          v: (room.areaWall || 0).toFixed(2),
          t: 'n',
        },
      ];
    });

    if (!roomRows) {
      return;
    }

    // const worksheet = XLSX.utils.json_to_sheet(roomRows);
    const worksheet = XLSX.utils.aoa_to_sheet([
      headers,
      ...getStyledRows(roomRows),
    ]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], {
      origin: 'A1',
    });

    const workbookName = getSafeExcelSheetName(
      `${surveyName} Room List`.substring(0, 31),
    );
    const fixedWorksheet = await autoSizeWorksheetWidth(worksheet);
    XLSX.utils.book_append_sheet(workbook, fixedWorksheet, workbookName);
    XLSX.writeFile(workbook, `${workbookName}.xlsx`);
  };

export const exportFormFieldsFromSurvey =
  ({
    formFields,
    formOptions,
  }: {
    formFields: FormField[];
    formOptions: FormOptions;
  }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { assetListId, name } = getState().surveyForm;
    const output = {
      formFields,
      formOptions,
      assetListId,
    };
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(output, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `${name}-form-fields.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

export const importExistingFormFieldsFromSurvey =
  ({
    survey,
    merge,
    currentFormFields,
  }: {
    survey: SurveyProps;
    merge: boolean;
    currentFormFields: FormField[];
  }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const clientId = getState().params.ids.clientId;
    const currentAssetListId = getState().surveyForm.assetListId;

    const currentAssetList = await getAssetListById(
      clientId,
      currentAssetListId,
    );
    const currentAssetListCode3List = currentAssetList.map((a) => a.code3);

    let newFields = survey.formFields;

    function updateNicheFields(nicheFields?: string[]) {
      if (!nicheFields) {
        return undefined;
      }
      return currentAssetListId === survey.assetListId
        ? nicheFields
        : nicheFields?.filter((nf) => currentAssetListCode3List.includes(nf));
    }

    // need to translate form fields if from very old survey, checking for formOptions will tell us if its old or new
    if (!survey.formOptions) {
      newFields = survey.formFields.map((field) => ({
        ...field,
        hidden: false,
        base: field.disabled,
        disabled: field.disabled,
        nicheFields: currentAssetListCode3List,
      }));
    } else {
      newFields = survey.formFields.map((field) => ({
        ...field,
        nicheFields: updateNicheFields(field.nicheFields),
      }));
    }

    if (merge) {
      const newFieldsMerged: FormField[] = [...currentFormFields];

      newFields.forEach((newField) => {
        const currentFieldIndex =
          currentFormFields.findIndex(
            (f) => f.id && newField.id && f.id === newField.id,
          ) ?? -1;

        if (currentFieldIndex !== -1) {
          newFieldsMerged[currentFieldIndex] = {
            ...currentFormFields[currentFieldIndex],
            ...newField,
          };
        } else if (!newField.base) {
          newFieldsMerged.push(newField);
        }
      });

      dispatch(
        setFormData({
          formFields: ensureQuantityFieldIsDecimal(newFieldsMerged),
          formOptions: survey.formOptions,
        }),
      );
    } else {
      dispatch(
        setFormData({
          formFields: ensureQuantityFieldIsDecimal(newFields),
          formOptions: survey.formOptions,
        }),
      );
    }
  };

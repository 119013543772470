import { Box, CardActionArea, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardAttribute from './CardAttribute';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import React from 'react';
import { getLastUpdatedStringFromTimestamp } from '../../../components/LastUpdatedTableCell';
import { makeStyles } from '@mui/styles';
import { surveyPropTypes } from '../../../shared/propTypes';

const useStyles = makeStyles({});

const SurveyCard = ({ survey }) => {
  const classes = useStyles();
  const lastUpdatedString = getLastUpdatedStringFromTimestamp(
    survey.lastUpdated.time,
  );
  const location = useLocation();

  return (
    <Card>
      <CardActionArea
        component={Link}
        to={`${location.pathname}/surveys/${survey.id}`}
      >
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box display="flex" alignItems="center">
              <Typography color="secondary" fontWeight={200} fontSize={22}>
                {survey.name}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box>
              <Box mb={1}>
                <CardAttribute
                  type="Site"
                  value={survey.siteName}
                  align="left"
                />
              </Box>
              <CardAttribute
                type="Building"
                value={survey.buildingName}
                align="left"
              />
            </Box>
            <CardAttribute
              type="Last Updated"
              align="left"
              value={lastUpdatedString}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

SurveyCard.propTypes = {
  survey: PropTypes.shape(surveyPropTypes).isRequired,
};

export default SurveyCard;

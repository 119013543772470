import { AppState, useAppDispatch } from '../../../../../config/store';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { ALL_ROLES } from '../../../../../config/roles';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { Role } from '../../../../../types/user';
import { UrlParams } from '../../../../../types/urlParams';
import { onSelectedSurveyReissue } from '../../../../../state/survey';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useSurvey from '../../../../../hooks/useSurvey';

const Reissue = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [surveyor, setSurveyor] = useState<Role | null>();
  const [resetRooms, setResetRooms] = useState<boolean>(false);
  const survey = useSurvey();
  const { clientId } = useParams<UrlParams>();

  // @ts-ignore
  useFirestoreConnect([
    {
      collection: 'roles',
      where: [
        ['clientId', '==', clientId],
        ['role', 'in', [ALL_ROLES.SURVEYOR, ALL_ROLES.LEAD_SURVEYOR]],
        ['deleted', '==', 0],
      ],
      storeAs: 'surveyors',
    },
  ]);

  const surveyors: Role[] = useSelector(
    (state: AppState) => state.firestore.ordered.surveyors || EMPTY_ARRAY,
  );
  return (
    <Paper sx={{ width: '100%', p: 2 }} id="main-content">
      <ErrorBoundary>
        <Typography variant="body1" fontSize={18} mb={2} color="secondary">
          Reissue
        </Typography>
        <Typography mb={2} color="secondary">
          Select a surveyor below in order to issue the{' '}
          <Typography component="span" color="secondary" fontWeight={700}>
            {survey.name}
          </Typography>{' '}
          survey to them.
        </Typography>

        <Autocomplete
          onChange={(e, data) => {
            setSurveyor(data);
          }}
          id="select-surveyor"
          options={surveyors}
          getOptionLabel={(surveyor) =>
            `${surveyor.nameFirst} ${surveyor.nameLast}`
          }
          isOptionEqualToValue={(option, value) => option.uid === value.uid}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Surveyor"
              variant="outlined"
              autoComplete="off"
            />
          )}
        />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={resetRooms}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setResetRooms(event.target.checked);
                  }}
                />
              }
              label="Reset room statuses to 'Not Started'?"
            />
            <Typography variant="caption">
              Switching on this setting will mean the surveyor must mark all
              rooms as complete before being able to finish the survey.
            </Typography>
          </FormGroup>

          <Button
            onClick={() => {
              if (!surveyor) {
                return;
              }
              dispatch(
                onSelectedSurveyReissue({
                  resetRooms,
                  survey: {
                    ...survey,
                    surveyorEmail: surveyor.email,
                    surveyorName: `${surveyor.nameFirst} ${surveyor.nameLast}`,
                    surveyorId: surveyor.uid,
                  },
                }),
              );
              history.push(`/${clientId}/surveys`);
            }}
            color="primary"
            variant="contained"
            disabled={!surveyor}
          >
            Confirm
          </Button>
        </Stack>
      </ErrorBoundary>
    </Paper>
  );
};

export default Reissue;

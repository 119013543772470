import { Avatar, Box, CardActionArea, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import Card from '@mui/material/Card';
import CardAttribute from './CardAttribute';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import { colours } from '../../../config/theme';
import { getUserRolePretty } from '../../../config/roles';
import { useParams } from 'react-router-dom';
import { userDataPropTypes } from '../../../shared/propTypes';

const UserCard = ({ user }) => {
  const createdDateString = new Date(user.created).toLocaleDateString();
  const userRolePretty = useMemo(() => getUserRolePretty(user.role), [user]);
  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Avatar
                  sx={{
                    bgcolor: colours.medium,
                    color: colours.white,
                    textTransform: 'uppercase',
                  }}
                  src={user.imageURL}
                >{`${user.nameFirst.slice(0, 1)}${user.nameLast.slice(
                  0,
                  1,
                )}`}</Avatar>
              </Box>
              <Typography color="secondary" fontWeight={200} fontSize={22}>
                {`${user.nameFirst} ${user.nameLast}`}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <CardAttribute type="Role" value={userRolePretty} align="left" />
            <CardAttribute
              type="Created"
              value={createdDateString}
              align="left"
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

UserCard.propTypes = {
  user: PropTypes.shape(userDataPropTypes).isRequired,
};

export default UserCard;

import { isViewingLocalServer, isViewingProdServer } from '../shared/utilities';

export const ENVIRONMENT = import.meta.env.VITE_CCC_ENVIRONMENT;
// export const ENVIRONMENT: string = 'staging' as const;
// export const ENVIRONMENT: string = 'prod' as const;

// must set to US location if testing cloud functions with remote services
// export const CLOUD_FUNCTION_LOCATION = 'us-central1';
export const CLOUD_FUNCTION_LOCATION = 'europe-west2';

const devStorageConfig = {
  DEV: 'gs://default-bucket',
  TAXONOMY: 'gs://default-bucket',
  ROOM_SCHEDULE: 'gs://default-bucket',
  IMAGE_FLOOR_PLAN: 'gs://default-bucket',
  IMAGE_ASSET: 'gs://default-bucket',
  IMAGE_LOGO: 'gs://default-bucket',
  IMAGE_BACKGROUND: 'gs://default-bucket',
  IMAGE_USER: 'gs://default-bucket',
};

const stagingStorageConfig = {
  DEV: 'gs://asseticom-dev-staging',
  TAXONOMY: 'gs://asseticom-nrm-staging',
  ROOM_SCHEDULE: 'gs://asseticom-room-schedule-staging',
  IMAGE_FLOOR_PLAN: 'gs://asseticom-image-floor-plan-staging',
  IMAGE_ASSET: 'gs://asseticom-image-asset-staging',
  IMAGE_LOGO: 'gs://asseticom-image-logo-staging',
  IMAGE_BACKGROUND: 'gs://asseticom-image-background-staging',
  IMAGE_USER: 'gs://asseticom-image-user-staging',
};

const prodStorageConfig = {
  DEV: 'gs://asseticom-dev',
  TAXONOMY: 'gs://asseticom-nrm',
  ROOM_SCHEDULE: 'gs://asseticom-room-schedule',
  IMAGE_FLOOR_PLAN: 'gs://asseticom-image-floor-plan',
  IMAGE_ASSET: 'gs://asseticom-image-asset',
  IMAGE_LOGO: 'gs://asseticom-image-logo',
  IMAGE_BACKGROUND: 'gs://asseticom-image-background',
  IMAGE_USER: 'gs://asseticom-image-user',
};

let storageConfig = stagingStorageConfig;

if (isViewingProdServer() || ENVIRONMENT === 'production') {
  storageConfig = prodStorageConfig;
} else if (isViewingLocalServer() && ENVIRONMENT === 'dev') {
  storageConfig = devStorageConfig;
}

export const PATHS = {
  STORAGE: storageConfig,
};

export const DRAWER_WIDTH = 240;
export const NOT_APPLICABLE = 'N/A';

export const EXPORT_PREVIEW_COLUMN_ORDER = [
  'Name',
  'Description',
  'Quantity',
  'UOM',
  'Cost',
  'Uplift',
  'Renewal_Multiplier',
  'Renewal_Cost',
  'Remaining_Life',
  'Expected_Life',
  'Reccurs',
];

export const FORMFIELD_DEFAULTS = [
  {
    label: 'Description',
    id: 'Description',
    default: '',
    base: true,
    required: false,
    disabled: false,
    hidden: false,
    type: 'Text',
    nicheFields: [],
    nicheFieldsExcludeMode: true,
  },
  {
    label: 'Quantity',
    id: 'Quantity',
    base: true,
    required: true,
    disabled: false,
    hidden: false,
    type: 'Decimal',
    min: 1,
    default: 1,
    nicheFields: [],
    nicheFieldsExcludeMode: true,
  },
  {
    label: 'Remaining Life',
    id: 'Remaining_Life',
    base: true,
    required: false,
    disabled: false,
    hidden: false,
    type: 'Number',
    nicheFields: [],
    nicheFieldsExcludeMode: true,
  },
];

export const FORM_FIELDS_NAME = 'formFields' as const;
export const FORM_OPTIONS_NAME = 'formOptions' as const;
export const FORM_FIELD_OPTIONS_NAME = 'fieldOptions' as const;

export const SITES_PER_PAGE_DEFAULT = 10;
export const SURVEYS_PER_PAGE_DEFAULT = 10;
export const ROOMS_PER_PAGE_DEFAULT = 10;
export const ASSETS_PER_PAGE_DEFAULT = 20;
export const ASSET_LISTS_PER_PAGE_DEFAULT = 10;
export const LOCAL_IP = '192.168.1.172';
export const UNSPECIFIED_LABEL = 'Unspecified';

export const CENTRE_ALIGN_HEADER = {
  muiTableHeadCellProps: {
    align: 'center',
  },
};

export const CENTRE_ALIGN_BODY = {
  muiTableBodyCellProps: {
    align: 'center',
  },
};

export const RIGHT_ALIGN_HEADER = {
  muiTableHeadCellProps: {
    align: 'right',
  },
};

export const RIGHT_ALIGN_BODY = {
  muiTableBodyCellProps: {
    align: 'right',
  },
};

export const BIG_ASSET_LIST_NUM_ROWS = 3000;
